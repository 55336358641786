import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import Welcome from './components/auth/Welcome';
import Footer from './components/Footer';
import { Auth } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import NotFound from './components/NotFound';
import AccountSearch from './components/accountsearch/AccountSearch'
import StoreLocator from './components/storelocator/StoreLocator';
import MyProfile from './components/myprofile/MyProfile';

library.add(faEdit);

class App extends Component {

  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    lead: null,
    contact: null
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  setLead = lead => {
    this.setState({ lead: lead });
  }

  setContact = contact => {
    this.setState({ contact: contact });
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
      this.setAuthStatus(true);
    } catch(error) {
      this.setAuthStatus(false);
        console.log(error);
    }
    this.setState({ isAuthenticating: false });
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      setAuthStatus: this.setAuthStatus,
      user: this.state.user,
      setUser: this.setUser,
      lead: this.state.lead,
      contact: this.state.contact,
      setLead: this.setLead,
      setContact: this.setContact
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
          <div>
              <Navbar auth={authProps} />
              <Switch>
                {/* With Switch, only one of the Routes will be activated. Else, it applies all */}
                <Route exact path="/" render={(props) => <Home />} />
                <Route path="/myprofile" render={(props) => <MyProfile {...props} auth={authProps} />} />
                <Route path="/memberdirectory" render={(props) => <AccountSearch {...props} auth={authProps} />} />
                <Route path="/storelocator" render={(props) => <StoreLocator {...props} auth={authProps}  />} />
                <Route path="/public" render={(props) => <StoreLocator {...props} auth={authProps}  />} />
                <Route path="/login" render={(props) => <LogIn {...props} auth={authProps} />} />
                <Route path="/register" render={(props) => <Register {...props} auth={authProps} />} />
                <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
                <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} />} />
                <Route exact path="/changepassword" render={(props) => <ChangePassword {...props} auth={authProps} />} />
                <Route exact path="/changepasswordconfirmation" render={(props) => <ChangePasswordConfirm {...props} auth={authProps} />} />
                <Route path="/welcome" render={(props) => <Welcome {...props} auth={authProps} />} />
                <Route path="*" render={(props) => <Welcome {...props} auth={authProps} />}>
                <NotFound/>
                </Route>
              </Switch>
            <Footer />
          </div>
      </div>
    );
  }
}

export default App;
