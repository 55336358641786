import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components';
import {Spinner} from '../utility/Spinner';


export const MainContainer = styled.div`
{
  align-items: center !important;
  display: flex !important;
  padding: 5em;
}
`;

export const ProfileInfo = styled.div`
{
  align-items: center !important;
  display: flex !important;
  flex: 1;
  flexDirection: row;
  flexWrap: wrap;
  alignItems: flex-start;
}
`;

export const ProfileTable = styled.table`
  tr {
    margin: 1em
  },
  td {
    padding: 1em
  }
  th {
    justify-content: space-between
  }
`


interface Profile  {
  firstName?: string,
  lastName?: string,
  email?: string,
  companyName?: string,
  mailingCity?:string,
  mailingCountry?:string,
  mailingPostalCode?:string,
  mailingState?:string,
  mailingStreet?:string,
  memberId?:string,
  mobilePhone?:string,
  website?:string,
  message?: string,
  errorCode?: string,
}


const MyProfile = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [profile, setProfile] = useState<Profile>({})
  const [processing, setProcessing] = useState(false);

  useEffect(()=> {
    fetchSFProfile();
  }, []);

  const fetchSFProfile= async () => {

    var contactid = props.auth.user.attributes['custom:contactid'];
    const requestInfo = {
      headers: {
			  // 'Content-Type': 'application/json',
        Authorization: props.auth.user.signInUserSession.idToken.jwtToken
      },
    }
    const profile = await API.get("salesforceapi", "/contact/get?id=" + contactid, requestInfo);
    setProfile(profile);
    if (profile.errorCode) {
      setIsMember(false)
    } else {
      setIsMember(true)
    }
    setIsLoading(false)
  }

  function setInput(key:string, value:string) {
    setProfile({ ...profile, [key]: value })
  }

  const handleSubmit = async () => {
    setProcessing(true);

    var contactid = props.auth.user.attributes['custom:contactid'];
    const requestInfo = {
      headers: {
			  // 'Content-Type': 'application/json',
        Authorization: props.auth.user.signInUserSession.idToken.jwtToken
      },
      body: {
        // salutation: profile.salutation,
        firstName: profile.firstName,
        lastName: profile.lastName,
        // title: profile.jobTitle,
        email: profile.email,
        website: profile.website,
        // workPhone: profile.companyPhone,
        mobilePhone: profile.mobilePhone,
        mailingStreet: profile.mailingStreet,
        mailingCity: profile.mailingCity,
        mailingState: profile.mailingState,
        mailingPostalCode: profile.mailingPostalCode,
        mailingCountry: 'USA',
        account: {
          accountName: profile.companyName
        }
      }
    }
    await API.post("salesforceapi", "/contact/update?id=" + contactid, requestInfo)
    .then((updatedContact) => {
      setProfile(updatedContact);
      props.history.push("/myprofile");
    }).finally(()=> {
      setProcessing(false);
    })

  }

  return (
    <>
    {isLoading && <Spinner id="basicSpinner" />}
    {!isLoading && 
    <MainContainer>
      {!isMember && <h1>Your application is pending approval. Please wait until your membership is confirmed</h1>}
      {isMember && 
        <form onSubmit={handleSubmit} autoComplete="off">
        <ProfileTable>
          <thead>
          <tr>
            <th colSpan={4}>
            <h1>Update My Profile</h1>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label>First Name</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('firstName', event.target.value)}
                value={profile.firstName}
                size={30}
              />
            </td>
            <td>
              <label>Last Name</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('lastName', event.target.value)}
                value={profile.lastName}
                size={30}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Email</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('email', event.target.value)}
                value={profile.email}
                size={30}
              />
            </td>
            <td>
              <label>Phone</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('mobilePhone', event.target.value)}
                value={profile.mobilePhone}
                size={30}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Company</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('companyName', event.target.value)}
                value={profile.companyName}
                size={30}
              />
            </td>
            <td>
              <label>Website</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('website', event.target.value)}
                value={profile.website}
                size={30}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Address</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('mailingStreet', event.target.value)}
                value={profile.mailingStreet}
                size={30}
              />
            </td>
            <td>
              <label>City</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('mailingCity', event.target.value)}
                value={profile.mailingCity}
                size={30}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>State</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('mailingState', event.target.value)}
                value={profile.mailingState}
                size={30}
              />
            </td>
            <td>
              <label>Zip</label>
            </td>
            <td>  
              <input
                onChange={event => setInput('mailingPostalCode', event.target.value)}
                value={profile.mailingPostalCode}
                size={30}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <button className="button is-success" disabled={processing}>
                {processing ? "Processing..." : "Update My Profile"}
              </button>
            </td>
            <td></td>
            <td>
                <a href="/changepassword">Change password?</a>
            </td>
          </tr>
          </tbody>
        </ProfileTable>
        </form>
      }
    </MainContainer>
    }
    </>
  )
}

export default MyProfile