import { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth, API } from "aws-amplify";
import { FormTable } from "../styled-components/StyledComponents"
import { states, memberTypes, countries, titleOptions } from '../utility/Constants'

class Register extends Component {
  state = {
    email: "",
    password: "",
    confirmpassword: "",
    title: "",
    firstname: "",
    lastname: "",
    companyName: "",
    jobTitle: "",
    website: "",
    memberType: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    annualRevenue: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    },
    processing: false,
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({processing : true});

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
      this.setState({processing : false});
      return;
    }

    try {
      this.createLead().then((contactid)=> {
        this.authSignup(contactid).then(() => {
          this.setState({processing : false});
          this.props.history.push("/welcome");
        })
      })
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    } 
  }

  authSignup = async (contactid) => {
    // AWS Cognito integration here
    const { email, password, firstname, lastname, companyName } = this.state;
    Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        given_name: firstname,
        family_name: lastname,
        name: companyName,
        'custom:contactid': contactid,
      }})
      .catch(error => {
        let err = null;
        !error.message ? err = { "message": error } : err = error;
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err
          }
        });
      })
  }
  
  createLead = async () => {

    const { email, title, firstname, lastname, companyName, phone,
      jobTitle, website, memberType, addressLine1, addressLine2, city,
      state, zip, country, annualRevenue } = this.state;

    const requestInfo = {
      body: {
        salutation: title,
        firstName: firstname,
        lastName: lastname,
        title: jobTitle,
        email: email,
        mobilePhone: phone,
        company: companyName,
        companyWebsite: website,
        memberType: memberType,
        annualRevenue: annualRevenue,
        maillingAddress: {
          street: addressLine1 + (addressLine2? ' ' + addressLine2: ''),
          city: city,
          state: state,
          postalCode: zip,
          country: country
        },
      }
    }

    const lead = await API.post("salesforceapi", "/createLead", requestInfo);
    this.props.auth.setLead(lead);
    this.setState({contactid:lead.memberId})
    return lead.memberId
  }

  onInputChange = event => {
    let id = event.target.id
    let value = event.target.value
    this.setState({
      [id]: value
    });
    document.getElementById(id).classList.remove("is-danger");
    if(id === "phone") {
      var phonenoregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if(!value.match(phonenoregex)) {
        document.getElementById(id).classList.add("is-danger");
      }
    } else if (id === "zip") {
      var zipregex = /^\d{5}$/;
      if(!value.match(zipregex)) {
        document.getElementById(id).classList.add("is-danger");
      }
    }
  }

  render() {
    return (
      <section className="section auth">
        <FormErrors formerrors={this.state.errors} />
        <div>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormTable>
             <th>Enter the information below</th>
             <tbody>
              <tr>
                <td>
                  Title:
                </td>
                <td>
                  <select 
                    onChange={this.onInputChange} 
                    className="select"
                    id="title"
                    aria-describedby="titleHelp">
                    <option value="">Select One</option>
                    {titleOptions.map((item) => {
                      return (
                      <option value={item}  >
                          {item}
                      </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  First Name:
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    id="firstname"
                    size={30}
                    aria-describedby="nameHelp"
                    placeholder="Enter first name"
                    value={this.state.firstname}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr><tr>
                <td>
                  Last Name:
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    id="lastname"
                    aria-describedby="nameHelp"
                    placeholder="Enter last name"
                    value={this.state.lastname}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Email (used as username):
                </td>
                <td>
                  <input
                    className="input"
                    type="email"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Password:
                </td><td>
                  <input
                    className="input"
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                    autoComplete="new-password"
                  />
                </td>
              </tr><tr><td>
                Confirm password:
              </td><td>
                  <input
                    className="input"
                    type="password"
                    id="confirmpassword"
                    placeholder="Confirm password"
                    value={this.state.confirmpassword}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Company name:
                </td><td>
                  <input
                    className="input"
                    type="text"
                    id="companyName"
                    aria-describedby="companyHelp"
                    placeholder="Enter company name"
                    value={this.state.companyName}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr><tr><td>
                Annual Revenue:
              </td><td>
                  <input
                    className="input"
                    type="number"
                    id="annualRevenue"
                    pattern="[0-9]*"
                    aria-describedby="annualRevenueHelp"
                    placeholder="Enter annaul revenue"
                    value={this.state.annualRevenue}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Website:
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    id="website"
                    aria-describedby="websiteHelp"
                    placeholder="Enter website"
                    value={this.state.website}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Job Title:
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    id="jobTitle"
                    size={30}
                    aria-describedby="jobTitleHelp"
                    placeholder="Enter Job Title"
                    value={this.state.jobTitle}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Member Type:
                </td>
                <td>
                  <select 
                    onChange={this.onInputChange} 
                    className="select"
                    id="memberType"
                    aria-describedby="memberTypeeHelp">
                    <option value="">Select One</option>
                    {memberTypes.map((item) => {
                      return (
                      <option value={item}  >
                          {item}
                      </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  Phone:
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    id="phone"
                    aria-describedby="phoneHelp"
                    placeholder="Enter phone number"
                    value={this.state.phone}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Street Address:
                </td><td>
                  <input
                    className="input"
                    type="text"
                    id="addressLine1"
                    aria-describedby="addressHelp"
                    placeholder="Enter street address"
                    value={this.state.addressLine1}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
                </tr>
                <tr>
                <td>
                  Apt/Suite:
                </td><td>
                  <input
                    className="input"
                    type="text"
                    id="addressLine2"
                    aria-describedby="addressHelp"
                    placeholder="Enter Apt/Suite"
                    value={this.state.addressLine2}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  City:
                </td><td>
                  <input
                    className="input"
                    type="text"
                    id="city"
                    aria-describedby="cityHelp"
                    placeholder="Enter city"
                    value={this.state.city}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
                </tr>
                <tr>
                <td>
                  State:
                </td><td>
                  <select 
                    onChange={this.onInputChange} 
                    className="select"
                    id="state"
                    aria-describedby="stateHelp">
                    <option value="">Select State</option>
                    {states.map((state) => {
                      return (
                      <option value={state.code}  >
                          {state.name}
                      </option>
                      );
                    })}
                  </select>
                </td>
                </tr>
                <tr>
                <td>
                  Zip:
                </td>
                  <td>
                  <input
                    className="input"
                    type="number"
                    id="zip"
                    aria-describedby="zipHelp"
                    placeholder="Enter Zip"
                    value={this.state.zip}
                    onChange={this.onInputChange}
                    disabled={this.state.processing}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Country:
                </td>
                <td>
                <select 
                    onChange={this.onInputChange} 
                    className="select"
                    id="country"
                    aria-describedby="stateHelp">
                    <option value="">Select Country</option>
                    {countries.map((item) => {
                      return (
                      <option value={item}  >
                          {item}
                      </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <button className="button is-success" disabled={this.state.processing}>
                    {this.state.processing ? "Processing..." : "Register"}
                  </button>
                </td>
              </tr>
              </tbody>
            </FormTable>
          </form>
        </div>
      </section>
    );
  }
}

export default Register;