import React, { useState } from 'react'
import { API } from 'aws-amplify'
import styled from "styled-components"
import { FormikErrors, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

interface Store {
  companyName: string,
  street: string,
  city: string, 
  state: string, 
  postalCode: string, 
  country: string,
  phone: string,
  website: string, 
  memberType: string,
  isMember: boolean,
  rosterList: []
}

interface Roster {
	rosterStatus: string,
	rosterName: string,
	rosterId: string,
	rosterGroup: string,
	rosterCode: string
}

const StoreLocator = () => {
  const [searching, setSearching] = useState(false);
	const [stores, setStores] = useState<Store[]>([]);
	const [initialLoad, setInitialLoad] = useState(true);

	const formik = useFormik({
		initialValues: { name: '', city: '', state: '', zip: '' },
		validationSchema: Yup.object({
      name: Yup.string()
      	.min(4, 'Company Name must be 4 characters or more'),
      city: Yup.string()
      	.min(4, 'City must be 4 characters or more'),
      state: Yup.string()
				.min(2, 'State must be 2 characters or more'),
      zip: Yup.string()
				.min(4, 'Zip must be 4 characters or more'),
    }),
		onReset() {
			setStores([])
			setInitialLoad(true)
			setSearching(false)
		},
		validate(values){
			let errors: FormikErrors<FormikValues> = {};
			if(values.name === '' && values.city === '' && values.state === '' && values.zip === '' ) {
				errors.city = "At least one field required"
			}
			return errors;
		},
		onSubmit: (values) => {
			setSearching(true);
			setStores([]) //clear existing results
			const requestInfo = {
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					companyName: values.name,
					city: values.city,
					state: values.state,
					postalCode: values.zip,
					isMember: true,
					memberType : []
				}
			}
			API.post("salesforceapi", "/searchAccounts", requestInfo).then(response => {
				setStores(response)
				setInitialLoad(false)
				setSearching(false)
			})
		},
	});

	const validateSearchCriteria = (name: string, city: string, state: string, zip: string) => {
		if(name === '' && city === ''  
		&& state === '' && zip === '' ) {
			return false
		} else {
			return true
		}
	}

  return (
		<div className="wrapper">
			<div className="content">
				<div className="Search">
					<h2>Search Member Companies</h2>
					<div>
						<form onSubmit={formik.handleSubmit}>
							<table>
									<tr key="00" >
										<td>
											<label>Company Name</label>
										</td>
										<td>
											<input
												id="name"
												size={20}
												type="text"
												disabled={searching}
												{...formik.getFieldProps('name')}
											/>
										</td>
										
										<td>
											<label>City</label>
										</td>
										<td>
											<input
												id="city" 
												type="text" 
												{...formik.getFieldProps('city')} 
												size={20}
												disabled={searching}
											/>
										</td>
										<td>
											<label>State</label>
										</td>
										<td>
											<input
												id="state" 
												type="state" 
												{...formik.getFieldProps('state')}
												size={20}
												disabled={searching}
											/>
										</td>
										<td>
											<label>Zip Code</label>
										</td>
										<td>
											<input
												id="zip" 
												type="zip" 
												{...formik.getFieldProps('zip')}
												size={20}
												disabled={searching}
											/>
										</td>
									</tr>
									<tr>
										<td colSpan={2}>
											{formik.touched.name && formik.errors.name ? (
													<ErrorMsg>{formik.errors.name}</ErrorMsg>
												) : null}
										</td>
										<td colSpan={2}>
											{formik.touched.city && formik.errors.city ? (
													<ErrorMsg>{formik.errors.city}</ErrorMsg>
												) : null}
										</td>
										<td colSpan={2}>
											{formik.touched.state && formik.errors.state ? (
													<ErrorMsg>{formik.errors.state}</ErrorMsg>
												) : null}
										</td>
										<td colSpan={2}>
											{formik.touched.zip && formik.errors.zip ? (
													<ErrorMsg>{formik.errors.zip}</ErrorMsg>
												) : null}
										</td>
									</tr>
									<tr>
										<td>
											<button className="button is-primary" type="submit" disabled={searching}>{searching?'Searching...':'Search'}</button>
										</td>
										<td colSpan={7}>
											<button className="button is-light" type="reset" onClick={()=>formik.resetForm()} >Clear</button>
										</td>
									</tr>
									{stores.length === 0 && !initialLoad && !searching &&
										<tr >
											<td colSpan={8}>{<ErrorMsg>No stores found. Please enter a valid search criteria</ErrorMsg>}</td>
										</tr>
									}
									{stores.length > 0 &&
										<tr>
											<td colSpan={8}>{stores.length} results</td>
										</tr>
									}
								</table>
								<table>
									{stores.length > 0 &&
										<tr key="table_header" >
											<th>Company Name</th>
											<th>Address</th>
											<th>Phone</th>
											<th>Website</th>
											<th>Roster</th>
										</tr>
									}
									{stores.map((store, i) => (
										<tr key={i} >
											<td>{store.companyName}</td>
											<td>{store.street} 
												<br/>{store.city} {store.state} {store.postalCode} 
												<br/> {store.country}
											</td>
											<td>{store.phone}</td>
											<td>{store.website}</td>
											<td>{store.rosterList.map((it:Roster) => {
													return (
														<>
															<span>{it.rosterName}</span>
															<br/>
														</>
													)})}
											</td>
										</tr>
									))}
							</table>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export const ErrorMsg = styled.div`
 { 
    display: flex !important;
		font-size: 1em;
    color: red;
    align-items: center;    
    line-height: 1.5;
    position: relative;
 }
`;

export default StoreLocator