import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NavLinkItemActive, NavLinkItem } from './styled-components/StyledComponents';

// export default class Navbar extends Component {
function Navbar(props) {
  const location = useLocation();
  const isMember = localStorage.getItem('isMember');
  const LinkItem = ({ label,link, active }) => (
    <div>
      {active? (
      <NavLinkItemActive>
        <Link to={link} className="navbar-item" >
          {label}
        </Link>
      </NavLinkItemActive>
      ) : (
        <NavLinkItem>
        <Link to={link} className="navbar-item" >
          {label}
        </Link>
      </NavLinkItem>
      )}
    </div>
  );

  const handleLogOut = async event => {
    // event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      props.auth.setContact(null);
    }catch(error) {
      console.log(error.message);
    }
  };
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/images/npa-logo.png" alt="Natural Products Association logo" />
          </Link>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <LinkItem label="Home" link="/" active={(location.pathname === "/")?true:false}/>
            <LinkItem label="Retail Store Locator" link="/storelocator" 
              active={(location.pathname === "/storelocator" || location.pathname === "/public")?true:false}/>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              {props.auth.isAuthenticated && isMember === 'true' && (
                <LinkItem label="Member Directory" link="/memberdirectory" 
                  active={(location.pathname.includes("memberdirectory"))?true:false}>Member Directory</LinkItem>
              )}
              {props.auth.isAuthenticated && props.auth.user && (
                <p>
                  Welcome! {props.auth.user.attributes['given_name']}
                </p>
              )}
              {!props.auth.isAuthenticated && (
                <div>
                  <Link to="/register" className="button is-primary">
                    <strong>Register</strong>
                  </Link>
                  <Link to="/login" className="button is-light">
                    Log in
                  </Link>
                </div>
              )}
              {props.auth.isAuthenticated && (
                <LinkItem label="My Profile" link="/myprofile" 
                  active={(location.pathname.includes("myprofile"))?true:false}/>
              )}
              {props.auth.isAuthenticated && (
                <Link to="/" onClick={handleLogOut} className="button is-light">
                  Log out
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    )
}

export default Navbar
