import { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import { API } from 'aws-amplify'

class LogIn extends Component {
  state = {
    username: "",
    password: "",
    errors: {
      cognito: null,
      blankfield: false
    },
    processing: false,
    contact: null
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  handleSubmit = async event => {
    this.setState({processing : true})
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      Auth.signIn(this.state.username, this.state.password)
      .then((user) => {
        this.fetchSFContact(user.attributes['custom:contactid'])
        .then(() => {
          this.props.auth.setAuthStatus(true);
          this.props.auth.setUser(user);
          this.setState({processing : false})
          this.props.history.push("/");
        })
      })
      .catch((error) => {
        console.log('error signing in', error.code);
        let err = null;
      if (error.code && error.code === 'PasswordResetRequiredException') {
        this.props.auth.setUser({email: this.state.username, message: "Welcome back to NPA member portal. We have upgraded our systems which requires you to verify your email addresses and pick a new password."})
        // await Auth.forgotPassword(this.state.username);
        this.props.history.push('/forgotpasswordverification');
      } else {
        !error.message ? err = { "message": error } : err = error;
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err
          }
        });
      }
      this.setState({processing : false})
      });
    } catch (error) {
      
    }

  };

  fetchSFContact = async (contactid) => {
    const contact = await API.get("salesforceapi", "/contact/get?id=" + contactid);
    console.log('contact', contact)
    if (contact.errorCode) {
      console.log("not a member");
      localStorage.setItem('isMember', 'false');
    } else {
      console.log("is a member");
      localStorage.setItem('isMember', 'true');
      this.setState({contact: contact});
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1>Log in</h1>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <div className="field">
              Email(username):
              <p className="control">
                <input
                  className="input"
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter username or email"
                  value={this.state.username}
                  onChange={this.onInputChange}
                  disabled={this.state.processing}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                Password:
                <input
                  className="input"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  disabled={this.state.processing}
                  autoComplete="new-password" 
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
                {/* <input
                  type="checkbox"
                  id="migratinguser"
                  onChange={this.onCheckboxToggle}
                  disabled={this.state.processing}
                /> We have updated our member portal. Please check this box only if you had an account previously and visiting this new website for the first time.  */}
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success" disabled={this.state.processing}>
                  {this.state.processing ? "Processing..." : "Login"}
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default LogIn;