import styled from 'styled-components';

export const NavLinkItemActive = styled.div`
  a {
    display: flex !important;
    font-weight: bold;
    background-color: #EAECEE;
    color: #4a4a4a;
    align-items: center;    
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    position: relative;
  }
`;

export const NavLinkItem = styled.div`
  a {
    display: flex !important;
    color: #4a4a4a;
    align-items: center;    
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    position: relative;
  }
`;

export const FormTable = styled.table`
  padding: 0.5em;
  th {
    colspan: 2
  }
  td {
    padding: 0.5em;
  }
`;