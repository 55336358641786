export default function Welcome() {
  return (
    <section className="section auth">
      <div className="container">
        <h1>Welcome!</h1>
        <p>You have successfully registered a new account in NPA Member portal.</p>
        <p>We've sent you an email with verification link. Please click on the link to verify your account.</p>
        <p>After you confirm your email, you will be able to use the member features.</p>
      </div>
    </section>
  )
}
