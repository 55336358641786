import React from 'react';

const Home = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <img src="/images/NPA_Member_Home_790x600.jpg" alt="Nation’s largest and oldest nonprofit organization" />
          {/* <img src="/images/npa-welcome.png" usemap="#image-map" alt="Nation’s largest and oldest nonprofit organization"  />
          <map name="image-map">
              <area alt="voice" title="voice" href="/voice" coords="259,330,336,227,195,-1,5,-1,0,330" shape="poly" />
              <area alt="exclusive" title="exclusive" href="/exclusive" coords="409,339,102" shape="circle" />
              <area alt="networking" title="networking" href="/networking" coords="342,210,470,210,568,1,227,2" shape="poly" />
              <area alt="visibility" title="visibility" href="/visibility" coords="491,229,535,311,792,318,791,5,598,4,584,26" shape="poly" />
              <area alt="credibility" title="credibility" href="/credibility" coords="485,443,545,352,789,346,790,641,608,641" shape="poly" />
              <area alt="learning" title="learning" href="/learning" coords="355,461,460,459,560,648,254,648,309,538,256,637" shape="poly" />
              <area alt="discounts" title="discounts" href="/discounts" coords="270,356,324,439,208,645,2,645,2,354" shape="poly" />
          </map> */}
        </div>
      </section>
      <div className="box cta">
        <p className="has-text-centered">
          {/*<span className="tag is-primary">New</span> TODO Twitter feeds here.*/}
        </p>
      </div>
      <section className="container">
        <div className="columns features">
          <div className="column is-4">
            <div className="card is-shady">
              <div className="card-content">
                <div className="content">
                  <h4>Press Releases</h4>
                  <p>Find industry facts and stats & find news releases. {/*TODO Latest press release here with a link to the historical listing at the Wordpress site.*/}</p>
                  <p><a href="https://www.npanational.org/news-room/">READ MORE</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="card is-shady">
              <div className="card-content">
                <div className="content">
                  <h4>NPA In The News</h4>
                  <p>{/*TODO Latest press release here with a link to the historical listing at the Wordpress site.*/}</p>
                  <p><a href="https://www.npanational.org/npa-in-the-news/">READ MORE</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="card is-shady">
              <div className="card-content">
                <div className="content">
                  <h4>Why Become A Member</h4>
                  <p>Membership is right for you if you work in natural industry segments including, but not limited to: dietary supplements, natural/organic food, probiotics, sports nutrition, personal care/cosmetics, home care, medical/functional foods, homeopathics and pet products.</p>
                  <p><a href="https://www.npanational.org/member-benefits/">READ MORE</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;