function validateForm(event, state) {
  // clear all error messages
  const inputs = document.getElementsByClassName("is-danger");
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].classList.contains("error")) {
      inputs[i].classList.remove("is-danger");
    }
  }
  if (state.hasOwnProperty("firstname") && state.firstname === "") {
    document.getElementById("firstname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("lastname") && state.lastname === "") {
    document.getElementById("lastname").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("email") && state.email === "") {
    document.getElementById("email").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("companyName") && state.companyName === "") {
    document.getElementById("companyName").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("annualRevenue") && state.annualRevenue === "") {
    document.getElementById("annualRevenue").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("website") && state.website === "") {
    document.getElementById("website").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("phone") && state.phone === "") {
    document.getElementById("phone").classList.add("is-danger");
    return { blankfield: true };
  } else if(state.phone) {
    var phonenoregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(!state.phone.match(phonenoregex)) {
      document.getElementById("phone").classList.add("is-danger");
      return { blankfield: true };
    }
  }
  if (state.hasOwnProperty("addressLine1") && state.addressLine1 === "") {
    document.getElementById("addressLine1").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("city") && state.city === "") {
    document.getElementById("city").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("state") && state.state === "") {
    document.getElementById("state").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("zip") && state.zip === "") {
    document.getElementById("zip").classList.add("is-danger");
    return { blankfield: true };
  } else if(state.zip) {
    var zipregex = /^\d{5}$/;
    if(!state.zip.match(zipregex)) {
      document.getElementById("zip").classList.add("is-danger");
      return { blankfield: true };
    }
  }

  if (
    state.hasOwnProperty("verificationcode") &&
    state.verificationcode === ""
  ) {
    document.getElementById("verificationcode").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("password") && state.password === "") {
    document.getElementById("password").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("password") && state.password.length < 8 ) {
    document.getElementById("password").classList.add("is-danger");
    return { passwordTooShort: true };
  }
  if (state.hasOwnProperty("oldpassword") && state.oldpassword === "") {
    document.getElementById("oldpassword").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("newpassword") && state.newpassword === "") {
    document.getElementById("newpassword").classList.add("is-danger");
    return { blankfield: true };
  }
  if (state.hasOwnProperty("confirmpassword") && state.confirmpassword === "") {
    document.getElementById("confirmpassword").classList.add("is-danger");
    return { blankfield: true };
  }
  if (
    state.hasOwnProperty("password") &&
    state.hasOwnProperty("confirmpassword") &&
    state.password !== state.confirmpassword
  ) {
    document.getElementById("password").classList.add("is-danger");
    document.getElementById("confirmpassword").classList.add("is-danger");
    return { passwordmatch: true };
  }

  if (
    state.hasOwnProperty("newpassword") &&
    state.hasOwnProperty("confirmpassword") &&
    state.newpassword !== state.confirmpassword
  ) {
    document.getElementById("newpassword").classList.add("is-danger");
    document.getElementById("confirmpassword").classList.add("is-danger");
    return { passwordmatch: true };
  }
  return;
}

export default validateForm;