// import React from 'react';
// import ReactDOM from 'react-dom';
// Warning: You are importing createRoot from "react-dom" which is not supported. You should instead import it from "react-dom/client".
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { SessionContextProvider } from './components/session/session-context';

// import '@aws-amplify/ui/dist/style.css';
import '@aws-amplify/ui-react/styles.css';


// import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


// Amplify.configure({
//   Auth: {
//     // mandatorySignIn: true,
//     region: config.cognito.REGION,
//     userPoolId: config.cognito.USER_POOL_ID,
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID
//   }
// });



// ReactDOM.render(<App />, document.getElementById('root'));
// Warning: ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17. Learn more: https://reactjs.org/link/switch-to-createroot

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SessionContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SessionContextProvider>,
);

