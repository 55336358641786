import { createContext, useState } from 'react';

const SessionContext = createContext({
  interests: [],
  totalInterests: 0,
  addInterest: (interest) => {},
  removeInterest: (interestId) => {},
  itemIsInterest: (interestId) => {}
});

export function SessionContextProvider(props) {
  const [userInterests, setUserInterests] = useState([]);

  function addInterestHandler(interest) {
    setUserInterests((prevUserInterests) => {
      return prevUserInterests.concat(interest);
    });
  }

  function removeInterestHandler(interestId) {
    setUserInterests(prevUserInterests => {
      return prevUserInterests.filter(interest => interest.id !== interestId);
    });
  }

  function itemIsInterestHandler(interestId) {
    return userInterests.some(interest => interest.id === interestId);
  }

  const context = {
    interests: userInterests,
    totalInterests: userInterests.length,
    addInterest: addInterestHandler,
    removeInterest: removeInterestHandler,
    itemIsInterest: itemIsInterestHandler
  };

  return (
    <SessionContext.Provider value={context}>
      {props.children}
    </SessionContext.Provider>
  );
}

export default SessionContext;
